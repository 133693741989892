@import url('https://fonts.googleapis.com/css?family=Droid+Sans');
@font-face {
  font-family: 'Sarasa Gothic SC';
  font-style: normal;
  font-weight: 400;
  src: url('https://github.com/bsides/horizoverlay/raw/chinesefont/src/css/fonts/sarasa-gothic-sc-regular.ttf'); /* IE9 Compat Modes */
  src: local('Sarasa Gothic SC'), local('SarasaGothic'),
    url('https://github.com/bsides/horizoverlay/raw/chinesefont/src/css/fonts/sarasa-gothic-sc-regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('https://github.com/bsides/horizoverlay/raw/chinesefont/src/css/fonts/sarasa-gothic-sc-regular.ttf')
      format('truetype'); /* Safari, Android, iOS */
}
*,
*::before,
*::after {
  box-sizing: inherit;
}

@-ms-viewport {
  width: device-width;
}

html,
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Droid Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif;
  background: transparent;
  overflow: hidden;
  height: 100%;
  font-size: 0.9rem;
  font-weight: normal;
  color: #fff;
  line-height: 1.5;
}
body .chinese {
  font-family: 'SarasaGothic', 'Droid Sans', -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif !important;
}
.resizable {
  background-image: url(../images/handle.png);
  background-position: bottom right;
  background-repeat: no-repeat;
}
